import React from 'react';
import PropTypes from 'prop-types';
import Picture, {
  propTypes as PicturePropTypes,
} from '../../../components/01_atoms/DefaultPicture';
import { propTypes as linkPropTypes } from '../../../components/01_atoms/Link';
import Link from '../../Link';
import Button from '../../Button';

import styles from './index.module.scss';

const BBHeroWithTwoButtons = ({ image, heading, subheading = '', primaryButton = null, secondaryButton = null }) => (
  <div className={`bb bb-hero-with-two-buttons ${styles['bb-hero-with-two-buttons']}`}>
    <div className={styles['bb-hero-with-two-buttons__image-container']}>
      <Picture {...image} />
    </div>
    <div className={styles['bb-hero-with-two-buttons__content-wrapper']}>
      <div className="container">
        <div className={styles['bb-hero-with-two-buttons__content']}>
          <h1 className={styles['bb-hero-with-two-buttons__heading']}>{heading}</h1>
          {subheading && (
            <div className={styles['bb-hero-with-two-buttons__subheading']}>{subheading}</div>
          )}

          <div className={styles['bb-hero-with-two-buttons__buttons']}>
            {secondaryButton && secondaryButton.nextLink && (
              <Link {...secondaryButton.nextLink}>
                <Button size="small" type="secondary">
                  {secondaryButton.label}
                </Button>
              </Link>
            )}
            {primaryButton && primaryButton.nextLink && (
              <Link {...primaryButton.nextLink}>
                <Button size="small">{primaryButton.label}</Button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
);

BBHeroWithTwoButtons.propTypes = {
  image: PropTypes.shape(PicturePropTypes).isRequired,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
  primaryButton: PropTypes.shape({
    nextLink: PropTypes.shape(linkPropTypes),
    label: PropTypes.string,
  }),
  secondaryButton: PropTypes.shape({
    nextLink: PropTypes.shape(linkPropTypes),
    label: PropTypes.string,
  }),
};

export default BBHeroWithTwoButtons;
